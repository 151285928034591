<template>
  <header class="elv-balance-verification-task-detail-header">
    <div class="elv-balance-verification-task-detail-header__title">
      <el-skeleton
        v-if="props.detailLoading && !props.taskDetail?.treasuryBalancePeriodNo"
        animated
        :throttle="200"
        class="elv-balance-verification-task-detail-header__title-text is-skeleton"
      >
        <template #template>
          <el-skeleton-item variant="h3" />
        </template>
      </el-skeleton>
      <h3 v-else class="elv-balance-verification-task-detail-header__title-text">
        {{ props.taskDetail?.treasuryBalancePeriodNo }}
      </h3>
      <div
        v-if="props.taskDetail?.status"
        :class="{ 'is-success': props.taskDetail?.status === 'SUCCESS' }"
        class="elv-balance-verification-task-detail-header__status"
      >
        <SvgIcon
          width="12"
          height="12"
          :name="props.taskDetail?.status === 'SUCCESS' ? 'checkmark--outline' : 'warning-emptyBg'"
        />
        {{ props.taskDetail?.status === 'SUCCESS' ? t('common.verified') : t('common.verificationFailed') }}
      </div>
    </div>

    <div class="elv-balance-verification-task-detail-header__info">
      <div class="elv-balance-verification-task-detail-header__info-left">
        <div class="elv-balance-verification-task-detail-header__info-item">
          <p class="elv-balance-verification-task-detail-header__info-item-title">ID:</p>
          <el-skeleton
            v-if="props.detailLoading && !props.taskDetail?.treasuryBalancePeriodNo"
            animated
            :throttle="200"
            class="elv-balance-verification-task-detail-header__info-item-content is-skeleton"
          >
            <template #template>
              <el-skeleton-item variant="p" />
            </template>
          </el-skeleton>
          <span v-else class="elv-balance-verification-task-detail-header__info-item-content">
            {{ props.taskDetail?.treasuryBalancePeriodNo }}
          </span>
        </div>
        <div class="elv-balance-verification-task-detail-header__info-item">
          <p class="elv-balance-verification-task-detail-header__info-item-title">
            {{ t('reconciliation.dataRange') }}:
          </p>
          <el-skeleton
            v-if="props.detailLoading && !props.taskDetail?.beginDatetime && !props.taskDetail?.endDatetime"
            animated
            :throttle="200"
            class="elv-balance-verification-task-detail-header__info-item-content is-datetime-skeleton"
          >
            <template #template>
              <el-skeleton-item variant="p" />
            </template>
          </el-skeleton>
          <span v-else class="elv-balance-verification-task-detail-header__info-item-content">
            {{ dayjs(props.taskDetail?.beginDatetime).tz(props.entityTimezone).format('YYYY/MM/DD HH:mm:ss') }} -
            {{ dayjs(props.taskDetail?.endDatetime).tz(props.entityTimezone).format('YYYY/MM/DD HH:mm:ss') }} ({{
              props.currentEntityTimezone
            }})
          </span>
        </div>
      </div>
      <div class="elv-balance-verification-task-detail-header__info-right">
        <ElvExportButton :params="props.exportParams" :noPermission="false" />
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import ElvExportButton from '@/components/Base/ElvExportButton.vue'
import { TreasuryBalanceVerificationDetailType } from '#/AccountsTypes'

type ExportParamsType = {
  type: string
  extra: Object
}

const props = defineProps({
  taskDetail: {
    type: Object as () => TreasuryBalanceVerificationDetailType | null,
    default: () => null
  },
  entityTimezone: {
    type: String,
    default: 'UTC'
  },
  exportParams: {
    type: Object as () => ExportParamsType,
    default: () => ({
      type: 'TREASURY_BALANCE_VERIFY_TASK',
      extra: {}
    })
  },
  currentEntityTimezone: {
    type: String,
    default: 'UTC+00:00'
  },
  detailLoading: {
    type: Boolean,
    default: false
  }
})

dayjs.extend(utc)
dayjs.extend(timezone)
const { t } = useI18n()
</script>

<style lang="scss" scoped>
.elv-balance-verification-task-detail-header {
  width: 100%;
  padding-left: 17px;
  padding-right: 10px;
  box-sizing: border-box;
  margin-bottom: 16px;

  .elv-balance-verification-task-detail-header__title {
    display: flex;
    align-items: center;
    margin-bottom: 4px;

    .elv-balance-verification-task-detail-header__title-text {
      color: #0e0f11;
      font-family: 'Plus Jakarta Sans';
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-right: 8px;

      &.is-skeleton {
        width: 100px;
        height: 20px;
      }
    }

    .elv-balance-verification-task-detail-header__status {
      display: flex;
      padding: 4px 8px;
      justify-content: center;
      align-items: center;
      gap: 2px;
      border-radius: 13px;
      background: #fceee9;
      color: #df2200;
      leading-trim: both;
      text-edge: cap;
      font-family: 'Barlow';
      font-size: 11px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      svg {
        fill: #df2200;
      }

      &.is-success {
        background: #f1f8ea;
        color: #4b8910;

        svg {
          fill: #4b8910;
        }
      }
    }
  }

  .elv-balance-verification-task-detail-header__info {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .elv-balance-verification-task-detail-header__info-left {
    display: flex;
    gap: 16px;

    .elv-balance-verification-task-detail-header__info-item {
      display: flex;
      align-items: center;
      gap: 8px;

      .elv-balance-verification-task-detail-header__info-item-title {
        color: #838d95;
        font-family: 'Plus Jakarta Sans';
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .elv-balance-verification-task-detail-header__info-item-content {
        color: #0e0f11;
        font-family: 'Barlow';
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        &.is-skeleton {
          width: 40px;
          height: 16px;
        }

        &.is-datetime-skeleton {
          width: 320px;
          height: 16px;
        }
      }

      &::after {
        content: '';
        display: block;
        width: 1px;
        height: 13px;
        background: #dde1e6;
      }

      &:last-child {
        &::after {
          display: none;
        }
      }
    }
  }
}
</style>
