<template>
  <div
    v-if="props.taskDetail?.isTransferAdded || props.taskDetail?.isTransferDeleted"
    class="elv-balance-verification-task-detail-tips-container"
  >
    <SvgIcon name="warning-alt-filled" width="13" height="13" />
    <div class="elv-balance-verification-task-detail-tips-container-content">
      <p v-if="props.taskDetail?.isTransferDeleted">
        <i v-if="props.taskDetail?.isTransferAdded && props.taskDetail?.isTransferDeleted">1.</i>
        {{ t('message.verificationTaskHaveBeenDeletedInfo') }}
        <i18n-t
          v-if="!(props.taskDetail?.isTransferAdded && props.taskDetail?.isTransferDeleted)"
          keypath="message.refreshVerificationTaskInfo"
          tag="p"
          scope="global"
        >
          <template #revalidate>
            <span
              v-loading="props.loading"
              :class="{ 'is-disabled': props.entityDetail?.treasuryVerifyStatus === 'DOING' }"
              @click="onRefreshVerificationTask"
              >{{ t('button.revalidate') }}</span
            >
          </template>
        </i18n-t>
      </p>

      <template v-if="props.taskDetail?.isTransferAdded">
        <p>
          <i v-if="props.taskDetail?.isTransferAdded && props.taskDetail?.isTransferDeleted">2.</i>
          <i18n-t keypath="message.verificationTaskHaveNewTransactionsInfo" tag="p" scope="global">
            <template #datatime>
              <b>
                &nbsp;{{
                  dayjs(props.entityDetail?.treasuryVerifyDatetime)
                    .tz(props.entityDetail?.timezone ?? 'UTC')
                    .format('YYYY/MM/DD HH:mm:ss')
                }}
                {{ props.currentEntityTimezone }}
              </b>
            </template>
          </i18n-t>
        </p>
        <i18n-t
          keypath="message.refreshVerificationTaskInfo"
          tag="p"
          scope="global"
          :class="{
            'has-margin-left': props.taskDetail?.isTransferAdded && props.taskDetail?.isTransferDeleted
          }"
        >
          <template #revalidate>
            <span
              v-loading="props.loading"
              :class="{ 'is-disabled': props.entityDetail?.treasuryVerifyStatus === 'DOING' }"
              @click="onRefreshVerificationTask"
              >{{ t('button.revalidate') }}</span
            >
          </template>
        </i18n-t>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { EntityDetailType } from '#/EntityTypes'
import { TreasuryBalanceVerificationDetailType } from '#/AccountsTypes'

const props = defineProps({
  taskDetail: {
    type: Object as () => TreasuryBalanceVerificationDetailType | null,
    default: () => null
  },
  loading: {
    type: Boolean,
    default: false
  },
  entityDetail: {
    type: Object as () => EntityDetailType | null,
    default: () => null
  },
  entityTimezone: {
    type: String,
    default: 'UTC'
  },
  currentEntityTimezone: {
    type: String,
    default: 'UTC+00:00'
  }
})

dayjs.extend(utc)
dayjs.extend(timezone)
const { t } = useI18n()
const emit = defineEmits(['onRefreshVerificationTask'])

/**
 * @description: 刷新验证任务
 */
const onRefreshVerificationTask = () => {
  if (props.entityDetail?.treasuryVerifyStatus === 'DOING') return
  emit('onRefreshVerificationTask')
}
</script>

<style lang="scss" scoped>
.elv-balance-verification-task-detail-tips-container {
  width: calc(100% - 27px);
  display: flex;
  padding: 7px 12px;
  margin-left: 17px;
  align-items: baseline;
  gap: 6px;
  align-self: stretch;
  border: 1px solid #ede8ce;
  background: #fcf9e8;
  margin-bottom: 12px;

  svg {
    transform: translateY(1px);
  }

  .elv-balance-verification-task-detail-tips-container-content {
    display: flex;
    flex-direction: column;
    flex: 1 0 0;

    p {
      display: flex;
      align-items: stretch;
      color: #746635;
      font-family: 'Plus Jakarta Sans';
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      &.has-margin-left {
        margin-left: 16px;
      }

      i {
        width: 16px;
        white-space: nowrap;
        font-style: normal;
      }

      p {
        display: flex;
      }

      span {
        cursor: pointer;
        color: #4674eb;
        font-family: 'Plus Jakarta Sans';
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration-line: underline;
        text-decoration-style: solid;
        text-decoration-skip-ink: auto;
        text-decoration-thickness: auto;
        text-underline-offset: auto;
        text-underline-position: from-font;

        &.is-disabled {
          cursor: not-allowed;
          color: #838d95;
        }

        :deep(.el-loading-mask) {
          background-color: rgba(252, 249, 232, 0.5);

          .el-loading-spinner {
            top: unset;
            bottom: -3px;
            right: -42px;

            svg {
              width: 16px;
              height: 16px;
            }
          }
        }
      }

      b {
        display: contents;
        color: #000;
        font-family: 'Plus Jakarta Sans';
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
      }
    }
  }
}
</style>
