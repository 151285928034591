<template>
  <div
    class="elv-balance-verification-task-detail-difference"
    :class="{
      'is-failed': props.taskDetail?.status === 'FAIL' || !new Big(props.taskDetail?.difference ?? '0').eq(0),
      'is-verified': props.taskDetail?.status === 'SUCCESS' && new Big(props.taskDetail?.difference ?? '0').eq(0)
    }"
  >
    <div
      v-if="
        (!isEmpty(props.taskDetail?.treasuryBalanceVerifyReason) && props.taskDetail?.status) ||
        (props.taskDetail?.status === 'FAIL' && !props.detailLoading)
      "
      class="elv-balance-verification-task-detail-difference-left"
    >
      <template v-if="!isEmpty(props.taskDetail?.treasuryBalanceVerifyReason) && props.taskDetail?.status">
        <ol class="elv-balance-verification-task-detail-difference-info">
          <li class="elv-balance-verification-task-detail-difference-info-item">
            <span class="elv-balance-verification-task-detail-difference-info-item__title"
              >{{ t('title.explainedBy') }}:</span
            >
            <p class="elv-balance-verification-task-detail-difference-info-item__value">
              {{
                props.taskDetail?.treasuryBalanceVerifyReason?.type === 'SYSTEM'
                  ? t('common.system')
                  : props.taskDetail?.explainByUser?.name
              }}
            </p>
          </li>
          <li class="elv-balance-verification-task-detail-difference-info-item">
            <span class="elv-balance-verification-task-detail-difference-info-item__title"
              >{{ t('common.dateTime') }}:</span
            >
            <p class="elv-balance-verification-task-detail-difference-info-item__value">
              {{ dayjs(props.taskDetail?.explainDatetime).tz(props.entityTimezone).format('YYYY/MM/DD HH:mm:ss') }}
            </p>
          </li>
          <li class="elv-balance-verification-task-detail-difference-info-item">
            <span class="elv-balance-verification-task-detail-difference-info-item__title"
              >{{ t('reconciliation.reason') }}:</span
            >
            <div class="elv-balance-verification-task-detail-difference-info-item__value">
              <div>{{ t('reconciliation.explained') }}</div>
              <p class="elv-balance-verification-task-detail-difference-info-item__value">
                {{ props.taskDetail?.treasuryBalanceVerifyReason?.name }}
              </p>
            </div>
          </li>
        </ol>

        <elv-button
          v-if="props.taskDetail?.treasuryBalanceVerifyReason?.type === 'USER'"
          height="32"
          round
          type="primary"
          :loading="clearLoading"
          @click="onClearExplanation"
          ><SvgIcon name="unlink" width="14" height="14" fill="#fff" />{{
            t('reconciliation.clearExplanation')
          }}</elv-button
        >
      </template>

      <elv-button
        v-if="props.taskDetail?.status === 'FAIL' && !props.detailLoading"
        class="elv-balance-verification-task-detail-difference-explain"
        height="32"
        :width="`fit-content`"
        round
        type="primary"
        @click="() => emit('onOpenExplanationDialog')"
        ><SvgIcon name="unlink" width="14" height="14" fill="#fff" />{{
          t('button.acceptDifferenceAndExplain')
        }}</elv-button
      >
    </div>

    <div class="elv-balance-verification-task-detail-difference-right">
      <ul class="elv-balance-verification-task-detail-difference-list">
        <li class="elv-balance-verification-task-detail-difference-item">
          <p class="elv-balance-verification-task-detail-difference-item-title">
            {{ t('common.difference') }}
          </p>
          <el-skeleton
            v-if="props.detailLoading && !currency"
            animated
            :throttle="200"
            class="elv-balance-verification-task-detail-difference-item-value is-skeleton"
          >
            <template #template>
              <el-skeleton-item variant="p" />
            </template>
          </el-skeleton>
          <div
            v-else
            class="elv-balance-verification-task-detail-difference-item-value"
            :title="`${formatNumber(props.taskDetail?.difference ?? '0', 20)} ${currency}`"
          >
            {{ formatNumberToSignificantDigits(props.taskDetail?.difference ?? '0', 2, '', false) }} {{ currency }}
          </div>
          <span
            v-if="!new Big(props.taskDetail?.difference ?? '0').eq(0)"
            :title="
              fieldValueFormat(
                new Big(props.taskDetail?.differenceFC ?? '0').toNumber() ?? null,
                {
                  price: true,
                  symbol: `${props.entityDetail?.underlyingCurrency?.sign || `${props.entityDetail?.underlyingCurrency?.showSymbol} `}`
                },
                'NUMBER'
              )
            "
            class="elv-balance-verification-task-detail-difference-item-reporting"
            >{{
              formatNumberToSignificantDigits(
                props.taskDetail?.differenceFC ?? '0',
                2,
                `${props.entityDetail?.underlyingCurrency?.sign || `${props.entityDetail?.underlyingCurrency?.showSymbol} `}`
              )
            }}
          </span>
        </li>
        <span class="elv-balance-verification-task-detail-difference-item-symbol">=</span>
        <li class="elv-balance-verification-task-detail-difference-item">
          <p class="elv-balance-verification-task-detail-difference-item-title">
            {{ t('title.endingSnapshot') }}
          </p>
          <el-skeleton
            v-if="props.detailLoading && !currency"
            animated
            :throttle="200"
            class="elv-balance-verification-task-detail-difference-item-value is-skeleton"
          >
            <template #template>
              <el-skeleton-item variant="p" />
            </template>
          </el-skeleton>
          <div
            v-else
            class="elv-balance-verification-task-detail-difference-item-value"
            :title="`${formatNumber(props.taskDetail?.calculateEndBalance ?? '0', 20)} ${currency}`"
          >
            {{ formatNumberToSignificantDigits(props.taskDetail?.calculateEndBalance ?? '0', 2, '', false) }}
            {{ currency }}
          </div>
        </li>
        <span class="elv-balance-verification-task-detail-difference-item-symbol">-</span>
        <li class="elv-balance-verification-task-detail-difference-item">
          <p class="elv-balance-verification-task-detail-difference-item-title">
            {{ t('title.endingBalance') }}
          </p>
          <el-skeleton
            v-if="props.detailLoading && !currency"
            animated
            :throttle="200"
            class="elv-balance-verification-task-detail-difference-item-value is-skeleton"
          >
            <template #template>
              <el-skeleton-item variant="p" />
            </template>
          </el-skeleton>

          <div
            v-else
            class="elv-balance-verification-task-detail-difference-item-value"
            :title="`${formatNumber(props.taskDetail?.endBalance ?? '0', 20)} ${currency}`"
          >
            {{ formatNumberToSignificantDigits(props.taskDetail?.endBalance ?? '0', 2, '', false) }} {{ currency }}
          </div>
        </li>
      </ul>

      <p class="elv-balance-verification-task-detail-difference-tolerance">
        {{ t('title.balanceVerifyToleranceTitle') }}:
        <span
          >{{
            props.entityDetail?.treasuryVerifyTolerance.type === 'DIFFERENCE'
              ? t('project.absoluteDifference')
              : t('project.absoluteDifferenceValue')
          }}&nbsp;≤&nbsp;
          {{ formatNumberWithCommas(props.entityDetail?.treasuryVerifyTolerance?.value ?? 0) }}
        </span>
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import Big from 'big.js'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { isEmpty } from 'lodash-es'
import { ElMessage } from 'element-plus'
import AccountsApi from '@/api/AccountsApi'
import timezone from 'dayjs/plugin/timezone'
import { EntityDetailType } from '#/EntityTypes'
import { TreasuryBalanceVerificationDetailType } from '#/AccountsTypes'
import { formatNumber, fieldValueFormat, formatNumberWithCommas, formatNumberToSignificantDigits } from '@/lib/utils'

const props = defineProps({
  taskDetail: {
    type: Object as () => TreasuryBalanceVerificationDetailType | null,
    default: () => null
  },
  detailLoading: {
    type: Boolean,
    default: false
  },
  entityDetail: {
    type: Object as () => EntityDetailType | null,
    default: () => null
  },
  entityTimezone: {
    type: String,
    default: 'UTC'
  }
})

dayjs.extend(utc)
dayjs.extend(timezone)
const { t } = useI18n()
const route = useRoute()

const clearLoading = ref(false)
const emit = defineEmits(['onResetDetail', 'onOpenExplanationDialog'])

const entityId = computed(() => {
  return String(route.params?.entityId as string)
})

const treasuryBalancePeriodId = computed(() => {
  return String(route.params?.treasuryBalancePeriodId as string)
})

const currency = computed(() => props.taskDetail?.underlyingCurrency?.showSymbol || '')

/**
 * @description: 清除余额校验解释
 */
const onClearExplanation = async () => {
  clearLoading.value = true
  try {
    await AccountsApi.clearTreasuryBalanceVerifyTaskExplain(entityId.value, treasuryBalancePeriodId.value)
    ElMessage.success(t('message.success'))
    emit('onResetDetail')
  } catch (error: any) {
    console.error(error)
    ElMessage.error(error.message)
  } finally {
    clearLoading.value = false
  }
}
</script>

<style lang="scss" scoped>
.elv-balance-verification-task-detail-difference {
  width: 100%;
  box-sizing: border-box;
  min-height: 172px;
  padding: 16px;

  &::after {
    content: '';
    display: block;
    clear: both;
  }

  &.is-failed {
    .elv-balance-verification-task-detail-difference-item:first-child {
      .elv-balance-verification-task-detail-difference-item-value {
        color: #ca1e00;
      }
    }
  }

  &.is-verified {
    .elv-balance-verification-task-detail-difference-item:first-child {
      .elv-balance-verification-task-detail-difference-item-value {
        color: #4b8910;
      }
    }
  }

  .elv-balance-verification-task-detail-difference-left {
    height: 123px;
    padding: 18px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    float: left;
  }

  .elv-balance-verification-task-detail-difference-info {
    display: flex;
    align-items: center;
    gap: 32px;

    .elv-balance-verification-task-detail-difference-info-item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .elv-balance-verification-task-detail-difference-info-item__title {
      color: #aaafb6;
      font-family: 'Plus Jakarta Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 6px;
    }

    .elv-balance-verification-task-detail-difference-info-item__value {
      color: #636b75;
      font-family: 'Barlow';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      display: flex;
      align-items: center;

      > div {
        display: flex;
        height: 18px;
        padding: 0px 8px;
        justify-content: center;
        align-items: center;
        border-radius: 40px;
        background: #eff4ff;
        margin-right: 4px;
        color: #1753eb;
        font-family: 'Plus Jakarta Sans';
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }

  .elv-balance-verification-task-detail-difference-right {
    display: flex;
    flex-direction: column;
    // position: absolute;
    // top: 16px;
    // right: 16px;
    float: right;

    .elv-balance-verification-task-detail-difference-tolerance {
      color: #aaafb6;
      font-family: 'Plus Jakarta Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-align: right;
      margin-top: 12px;

      span {
        margin-left: 4px;
      }
    }
  }

  .elv-balance-verification-task-detail-difference-list {
    display: flex;
    padding: 12px 32px;
    align-items: flex-start;
    gap: 16px;
    border-radius: 3px;
    border: 1px solid #edf0f3;
    background: #eff4fa;

    .elv-balance-verification-task-detail-difference-item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: fit-content;
      min-width: 72px;

      &:first-child {
        align-items: flex-end;
      }
    }

    .elv-balance-verification-task-detail-difference-item-symbol {
      color: #1e2024;
      font-family: 'Plus Jakarta Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      height: 54px;
      display: flex;
      align-items: flex-end;
      padding-bottom: 4px;
      box-sizing: border-box;
    }

    .elv-balance-verification-task-detail-difference-item-reporting {
      color: #838d95;
      font-family: 'Barlow';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .elv-balance-verification-task-detail-difference-item-title {
      color: #838d95;
      font-family: 'Plus Jakarta Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 12px;
    }

    .elv-balance-verification-task-detail-difference-item-value {
      color: #0e0f11;
      font-family: 'Barlow';
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;

      &.is-skeleton {
        --el-skeleton-color: #ced7e0;
        width: 80px;
        height: 24px;
      }
    }
  }
}
</style>
