<template>
  <div class="elv-balance-verification-task-detail-interval-balance">
    <div class="elv-balance-verification-task-detail-interval-balance__info">
      <p class="elv-balance-verification-task-detail-interval-balance__title">
        {{ props.interval === 'STARTING' ? t('title.startingSnapshot') : t('title.endingSnapshot') }}
      </p>

      <el-skeleton
        v-if="props.detailLoading && !props.datetime"
        animated
        :throttle="200"
        class="elv-balance-verification-task-detail-interval-balance__datetime is-skeleton"
      >
        <template #template>
          <el-skeleton-item variant="p" />
        </template>
      </el-skeleton>
      <p v-else class="elv-balance-verification-task-detail-interval-balance__datetime">
        {{ dayjs(props.datetime).tz(props.entityTimezone).format('YYYY/MM/DD HH:mm:ss') }}
        {{ props.currentEntityTimezone }}
      </p>

      <el-skeleton
        v-if="props.detailLoading"
        animated
        :throttle="200"
        class="elv-balance-verification-task-detail-balance-source-skeleton"
      >
        <template #template>
          <el-skeleton-item variant="circle" />
          <el-skeleton-item variant="h3" />
        </template>
      </el-skeleton>
      <div v-else class="elv-balance-verification-task-detail-interval-balance__source">
        <img
          v-if="props.source?.provider?.logo"
          :src="props.source?.provider?.logo || ''"
          :alt="props.source?.provider?.name || ''"
        />
        <span>{{ isEmpty(props.source) ? '-' : props.source?.name }}</span>
      </div>
    </div>

    <el-skeleton
      v-if="props.detailLoading && !props.currency"
      animated
      :throttle="200"
      class="elv-balance-verification-task-detail-interval-balance__amount is-skeleton"
    >
      <template #template>
        <el-skeleton-item variant="p" />
      </template>
    </el-skeleton>

    <div
      v-else
      class="elv-balance-verification-task-detail-interval-balance__amount"
      :title="`${isEmptyBeginDate && isEmpty(props.source) ? '-' : `${formatNumber(props.balance ?? '0', 20)} ${props.currency}`}`"
    >
      <template v-if="isEmptyBeginDate && isEmpty(props.source)">-</template>
      <template v-else
        >{{ formatNumberToSignificantDigits(props.balance ?? '0', 2, '', false) }}&nbsp;{{ props.currency }}</template
      >
    </div>
  </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { isEmpty } from 'lodash-es'
import { SourceType } from '#/AccountsTypes'
import timezone from 'dayjs/plugin/timezone'
import { formatNumber, formatNumberToSignificantDigits } from '@/lib/utils'

const props = defineProps({
  interval: {
    type: String,
    required: true // STARTING | ENDING
  },
  datetime: {
    type: String,
    default: ''
  },
  source: {
    type: Object as () => SourceType | null,
    default: null
  },
  entityTimezone: {
    type: String,
    default: 'UTC'
  },
  currency: {
    type: String,
    required: true
  },
  currentEntityTimezone: {
    type: String,
    default: 'UTC+00:00'
  },
  balance: {
    type: String,
    required: true
  },
  detailLoading: {
    type: Boolean,
    default: false
  }
})

dayjs.extend(utc)
dayjs.extend(timezone)
const { t } = useI18n()

const isEmptyBeginDate = computed(() => {
  return dayjs(props.datetime).isSame('1970-01-01T00:00:00.000Z')
})
</script>

<style lang="scss" scoped>
.elv-balance-verification-task-detail-interval-balance {
  display: flex;
  width: 100%;
  height: 43px;
  box-sizing: border-box;
  padding: 0 16px;
  align-items: center;
  justify-content: space-between;
  background-color: #edf0f3;
  border-top: 1px solid #ced7e0;

  .elv-balance-verification-task-detail-interval-balance__info {
    display: flex;
    align-items: center;
  }

  .elv-balance-verification-task-detail-interval-balance__title {
    color: #0e0f11;
    font-family: 'Plus Jakarta Sans';
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-right: 12px;
    white-space: nowrap;
  }

  .elv-balance-verification-task-detail-interval-balance__datetime {
    color: #838d95;
    leading-trim: both;
    text-edge: cap;
    font-family: 'Barlow';
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-right: 8px;
    white-space: nowrap;

    &.is-skeleton {
      --el-skeleton-color: #ced7e0;
      width: 190px;
      height: 16px;
    }
  }

  .elv-balance-verification-task-detail-balance-source-skeleton {
    --el-skeleton-color: #ced7e0;
    display: flex;
    align-items: center;

    .el-skeleton__item.el-skeleton__circle {
      width: 14px;
      height: 14px;
      margin-right: 4px;
    }

    .el-skeleton__item.el-skeleton__h3 {
      width: 80px;
      height: 14px;
    }
  }

  .elv-balance-verification-task-detail-interval-balance__source {
    display: flex;
    align-items: center;
    gap: 4px;
    color: #838d95;
    leading-trim: both;
    text-edge: cap;
    font-family: 'Plus Jakarta Sans';
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    img {
      width: 14px;
      height: 14px;
      border-radius: 50%;
    }
  }

  .elv-balance-verification-task-detail-interval-balance__amount {
    color: #0e0f11;
    font-family: 'Barlow';
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    &.is-skeleton {
      --el-skeleton-color: #ced7e0;
      width: 80px;
      height: 18px;
    }
  }
}
</style>
