<template>
  <div class="elv-balance-verification-task-detail-ending-balance__info">
    <div class="elv-balance-verification-task-detail-ending-balance__info-left">
      <h6>{{ t('title.endingBalanceInfo') }}</h6>
      <div class="elv-balance-verification-task-detail-ending-balance__info-formula">
        <span>=</span>
        <p>{{ t('title.startingBalance') }}</p>
        <span>+</span>
        <p>{{ t('report.inflow') }}</p>
        <span>-</span>
        <p>{{ t('report.outflow') }}</p>
      </div>
    </div>
    <el-skeleton
      v-if="props.detailLoading && !props.currency"
      animated
      :throttle="200"
      class="elv-balance-verification-task-detail-ending-balance__info-amount is-skeleton"
    >
      <template #template>
        <el-skeleton-item variant="p" />
      </template>
    </el-skeleton>
    <div
      v-else
      class="elv-balance-verification-task-detail-ending-balance__info-amount"
      :title="`${formatNumber(props.balance ?? '0', 20)} ${currency}`"
    >
      {{ formatNumberToSignificantDigits(props.balance ?? '0', 2, '', false) }}&nbsp;{{ props.currency }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { formatNumber, formatNumberToSignificantDigits } from '@/lib/utils'

const props = defineProps({
  currency: {
    type: String,
    required: true
  },
  balance: {
    type: String,
    required: true
  },
  detailLoading: {
    type: Boolean,
    default: false
  }
})

const { t } = useI18n()
</script>

<style lang="scss" scoped>
.elv-balance-verification-task-detail-ending-balance__info {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 67px;
  padding: 12px 16px;
  border-top: 1px solid #ced7e0;
  border-bottom: 1px solid #ced7e0;
  background: #f9fafb;
  box-sizing: border-box;

  h6 {
    color: #0e0f11;
    font-family: 'Plus Jakarta Sans';
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 8px;
  }

  .elv-balance-verification-task-detail-ending-balance__info-formula {
    display: flex;
    align-items: center;
    gap: 4px;
    color: #838d95;

    span {
      font-family: 'Barlow';
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    p {
      display: flex;
      justify-content: center;
      padding: 4px;
      height: 16px;
      box-sizing: border-box;
      align-items: center;
      border-radius: 2px;
      border: 0.5px solid #d0d4d9;
      gap: 10px;
      font-family: 'Plus Jakarta Sans';
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .elv-balance-verification-task-detail-ending-balance__info-amount {
    color: #0e0f11;
    font-family: 'Barlow';
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    &.is-skeleton {
      --el-skeleton-color: #ced7e0;
      width: 80px;
      height: 18px;
    }
  }
}
</style>
