<template>
  <div class="elv-balance-verify-task-transfer-table-container">
    <div class="elv-balance-verify-task-transfer-table-content">
      <el-table
        ref="tableListRef"
        v-table-skeleton="{ loading: props.tableLoading }"
        :data="props.tableData.list"
        border
        height="100%"
        row-key="transactionId"
        class="elv-balance-verify-task-transfer-table"
        header-cell-class-name="elv-balance-verify-task-transfer-table-header__cell"
        header-row-class-name="elv-balance-verify-task-transfer-table-header"
        :row-class-name="rowClassName"
        cell-class-name="elv-balance-verify-task-transfer-table-row__cell"
        @row-click="onCellClicked"
      >
        <template v-if="showPreviousTransfers" #append>
          <div
            class="elv-balance-verify-task-transfer-table-row__cell-append"
            @click="onShowOutsideTransfers(TreasuryBalanceVerificationTaskOutsideDirectionType.PREVIOUS)"
          >
            <span>{{ t('button.showPreviousTransfers') }}</span>
            <SvgIcon name="down-triangular-arrow" width="16" height="16" fill="#838D95" />
          </div>
        </template>
        <el-table-column
          :min-width="45"
          align="center"
          type="index"
          show-overflow-tooltip
          class-name="elv-balance-verify-task-transfer-table-row__index"
        >
          <template #default="{ row }">{{ row.rowIndex }} </template>
        </el-table-column>
        <el-table-column width="120" :label="t('common.type')">
          <template #default="{ row }">
            <div class="elv-balance-verify-task-transfer-table-row__cell-type">
              <SvgIcon :name="typeData(row)?.icon" width="14" height="14" />
              <span> {{ platformType(row.platformType) }}</span>
              <span>-{{ transformI18n(typeData(row)?.text) }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column width="150" :label="t('common.dateTime')">
          <template #default="{ row }">
            <DateTimeCell :params="{ data: row }" />
          </template>
        </el-table-column>
        <el-table-column :label="t('report.Base Asset')" width="120">
          <template #default="{ row }">
            <CurrencyCell :params="{ data: row, value: { cellName: 'Currency' } }" type="transactions" />
          </template>
        </el-table-column>
        <el-table-column
          :label="t('report.Amount (In)')"
          min-width="140"
          label-class-name="elv-balance-verify-task-transfer-table-row__amount"
          align="right"
          header-align="right"
        >
          <template #default="{ row }">
            <BorrowCell :params="{ data: row, value: { cellName: 'Amount (In)', tableType: 'transactions' } }" />
          </template>
        </el-table-column>
        <el-table-column
          :label="t('report.Amount (Out)')"
          min-width="140"
          label-class-name="elv-balance-verify-task-transfer-table-row__amount"
          align="right"
          header-align="right"
        >
          <template #default="{ row }">
            <BorrowCell :params="{ data: row, value: { cellName: 'Amount (Out)', tableType: 'transactions' } }" />
          </template>
        </el-table-column>
        <el-table-column :label="`${t('common.account')}/${t('common.counterparty')}`" min-width="370">
          <template #default="{ row }">
            <FromToCell
              :params="{ data: row }"
              :quickCreationCounterparty="true"
              @onViewDetail="onViewCounterpartyDetail(row)"
              @onAddToCounterparty="onAddToCounterparty"
              @onCreateNewCounterparty="onCreateNewCounterparty"
            />
          </template>
        </el-table-column>

        <el-table-column :label="t('report.balanceVerify')" min-width="110">
          <template #default="{ row }">
            <el-tooltip
              :show-arrow="false"
              effect="dark"
              placement="top"
              popper-class="elv-report-table-tips"
              overlay-classname="elv-report-table-tips"
              :show-after="500"
            >
              <div class="elv-balance-verify-task-transfer-table-row__balance-verify">
                <SvgIcon v-if="row.verifyStatus === 'WAITING'" class="waiting" name="pending" width="16" height="16" />
                <SvgIcon v-else-if="row.verifyStatus === 'FAIL'" name="help-filled-verify" width="16" height="16" />
                <SvgIcon v-else class="success" name="checkmark-filled" width="16" height="16" fill="#80E220" />
              </div>

              <template #content>
                <p v-if="row.verifyStatus === 'WAITING'">{{ t('message.balanceNotVerified') }}</p>
                <p v-else-if="row.verifyStatus === 'FAIL'">
                  {{ t('message.balanceVerificationFailed') }}{{ getProvider(row) }}
                </p>
                <p v-else>{{ t('message.balanceVerificationPassed') }}{{ getProvider(row) }}</p>
              </template>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          :label="t('report.auxiliaryCode')"
          min-width="200"
          class-name="elv-balance-verify-task-transfer-table-row__auxiliary"
        >
          <template #default="{ row }">
            <template v-if="row?.auxiliaryValueList?.length">
              <div
                v-for="auxiliaryValue in row.auxiliaryValueList"
                :key="auxiliaryValue.auxiliaryTypeId"
                class="elv-balance-verify-task-transfer-table-row__auxiliary-list"
              >
                <template v-if="auxiliaryValue?.auxiliaryItemList?.length">
                  <div
                    v-for="auxiliary in auxiliaryValue.auxiliaryItemList"
                    :key="auxiliary.auxiliaryItemId"
                    class="elv-balance-verify-task-transfer-table-row__auxiliary-item"
                  >
                    {{ auxiliaryValue.auxiliaryType?.name }}: {{ auxiliary?.value }}
                  </div>
                </template>
                <template v-else-if="auxiliaryValue?.counterpartyList?.length">
                  <div
                    v-for="auxiliary in auxiliaryValue.counterpartyList"
                    :key="auxiliary.counterpartyId"
                    class="elv-balance-verify-task-transfer-table-row__auxiliary-item"
                  >
                    {{ auxiliaryValue.auxiliaryType?.name }}: {{ auxiliary?.name }}
                  </div>
                </template>
                <template v-else-if="auxiliaryValue?.entityAccountList?.length">
                  <div
                    v-for="auxiliary in auxiliaryValue.entityAccountList"
                    :key="auxiliary.entityAccountId"
                    class="elv-balance-verify-task-transfer-table-row__auxiliary-item"
                  >
                    {{ auxiliaryValue.auxiliaryType?.name }}: {{ auxiliary?.name }}
                  </div>
                </template>
              </div>
            </template>
            <div v-else>-</div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div
      v-if="!props.tableLoading && (!props.tableData?.list?.length || props.isLastPage) && !subsequentTransfers"
      class="elv-balance-verify-task-transfer-table-bottom-append"
    >
      <div
        class="elv-balance-verify-task-transfer-table-bottom-append__cell"
        @click="onShowOutsideTransfers(TreasuryBalanceVerificationTaskOutsideDirectionType.SUBSEQUENT)"
      >
        <span>{{ t('button.showSubsequentTransfers') }}</span>
        <SvgIcon name="down-triangular-arrow" width="16" height="16" fill="#838D95" />
      </div>
    </div>
  </div>

  <elv-pagination
    hide-on-single-page
    class="elv-balance-verify-task-transfer-list-pagination"
    size-popper-class="elv-transactions-pagination__sizes-popper"
    :limit="props.transactionsParams.limit"
    :current-page="props.transactionsParams.page"
    :total="props.tableData.totalCount"
    @current-change="onChangePage"
    @size-change="onChangePageSize"
  />

  <TransferDetailOverlay
    ref="transactionEditOverlayRef"
    :isBatch="false"
    :currentData="overlayDrawerData.currentData"
    @onResetList="onResetList"
  />

  <SelectCounterpartyDialog
    ref="selectCounterpartyDialogRef"
    :type="addToCounterpartyType === 'add' ? 'list' : 'edit'"
    :currentData="currentData"
    @onReset="onResetList"
  />

  <CounterpartyDialog
    ref="counterpartyDialogRef"
    tableType="transactions"
    :currentData="currentData"
    model="add"
    @resetList="onResetList"
  />

  <CounterPartyDetailDrawer ref="counterPartyOverlayRef" :drawerData="currentData" @resetList="onResetList" />
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { flatMap } from 'lodash-es'
import { ElMessage } from 'element-plus'
import { transformI18n } from '@/i18n/index'
import { transactionType } from '@/config/index'
import { useEntityStore } from '@/stores/modules/entity'
import { TransactionItemType } from '#/TransactionsTypes'
import BorrowCell from '@/components/Reports/Cell/BorrowCell.vue'
import FromToCell from '@/components/Reports/Cell/FromToCell.vue'
import DateTimeCell from '@/components/Reports/Cell/DateTimeCell.vue'
import CurrencyCell from '@/components/Reports/Cell/CurrencyCell.vue'
import { TreasuryBalanceVerificationTaskOutsideDirectionType } from '#/AccountsTypes'
import TransferDetailOverlay from '../../../../../components/Transfer/DetailOverlay.vue'
import CounterpartyDialog from '../../../../../components/Counterparty/CounterpartyDialog.vue'
import CounterPartyDetailDrawer from '../../../../../components/Counterparty/DetailDrawer.vue'
import SelectCounterpartyDialog from '../../../../../components/Counterparty/SelectCounterpartyDialog.vue'

const props = defineProps({
  tableData: {
    type: Object,
    required: true
  },
  tableLoading: {
    type: Boolean,
    default: false
  },
  isLastPage: {
    type: Boolean,
    default: false
  },
  transactionsParams: {
    type: Object,
    required: true
  }
})

const { t } = useI18n()
const entityStore = useEntityStore()
const emit = defineEmits(['onExport', 'onResetList', 'onChangePage', 'onChangePageSize', 'onShowOutsideTransfers'])

const tableListRef = useTemplateRef('tableListRef')
const counterpartyDialogRef = useTemplateRef('counterpartyDialogRef')
const selectCounterpartyDialogRef = useTemplateRef('selectCounterpartyDialogRef')
const transactionEditOverlayRef = useTemplateRef('transactionEditOverlayRef')
const counterPartyOverlayRef = useTemplateRef('counterPartyOverlayRef')
const addToCounterpartyType = ref('add')
const overlayDrawerData: any = reactive({
  type: 'contact',
  drawerTitle: 'Contact',
  currentData: {},
  isBatch: false,
  journalModel: 'add'
})
const previousTransfers = defineModel<any>('previousTransfers', { required: true })
const subsequentTransfers = defineModel<any>('subsequentTransfers', { required: true })

const currentData: any = ref({})

const multipleSelection: any = ref([])

const currentEntityPermission = computed(() => {
  return entityStore.entityPermission()
})

const typeData = computed(() => {
  return (row: TransactionItemType) => {
    let data: any = {}
    const transactionTypeList = flatMap(transactionType, 'list')
    transactionTypeList.forEach((item) => {
      if (item.value === row.type) {
        data = {
          icon: item.icon,
          text: item.label
        }
      }
    })
    return data
  }
})

const showPreviousTransfers = computed(() => {
  return !previousTransfers.value && !props.tableLoading && props.transactionsParams.page === 1
})

const tableContentTopBorder = computed(() => {
  return showPreviousTransfers.value ? '1px solid #dde1e6' : 'none'
})

const containerHeight = computed(() => {
  return props.transactionsParams.page === 1 && props.isLastPage ? '100%' : 'calc(100% - 58px)'
})

const platformType = computed(() => {
  return (type: string) => {
    let text = ''
    switch (type) {
      case 'CHAIN':
        text = t('common.onChain')
        break
      case 'EXCHANGE':
        text = t('common.exchange')
        break
      case 'BANK':
        text = t('common.bank')
        break
      case 'CUSTODY':
        text = t('common.custody')
        break
      case 'PAYMENT':
        text = t('common.payment')
        break
      default:
        text = ''
        break
    }
    return text
  }
})

/**
 * @description: 重置列表
 * @param {number} page
 */
const onChangePage = (page: number) => {
  emit('onChangePage', page)
}

/**
 * @description: 获取供应商
 * @param {*} row
 */
const getProvider = (row: any) => {
  if (row?.entityAccount.verifyProvider) {
    return `(${row?.entityAccount.verifyProvider?.name})`
  }
  return ''
}

/**
 * @description: 改变分页大小
 * @param {number} limit
 */
const onChangePageSize = (limit: number) => {
  emit('onChangePageSize', limit)
}

/**
 * 余额校验任务区间外的交易列表
 * @param {string} direction PREVIOUS | SUBSEQUENT
 */
const onShowOutsideTransfers = (direction: TreasuryBalanceVerificationTaskOutsideDirectionType) => {
  if (direction === TreasuryBalanceVerificationTaskOutsideDirectionType.PREVIOUS) {
    previousTransfers.value = true
  } else {
    subsequentTransfers.value = true
  }
  emit('onShowOutsideTransfers', direction)
}

/**
 * @description: 创建新的对手方
 */
const onCreateNewCounterparty = () => {
  if (
    ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
    !currentEntityPermission.value?.counterparty?.create
  ) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  counterpartyDialogRef.value?.onCheckCounterpartyDialog()
}

/**
 * @description: 查看对手方详情
 * @param {*} row
 */
const onViewCounterpartyDetail = (row: any) => {
  if (
    ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
    !currentEntityPermission.value?.transaction?.viewDetail
  ) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  currentData.value = row
  overlayDrawerData.currentData = row
  counterPartyOverlayRef.value?.onCheckDrawerStatus()
}

/**
 * @description: 添加至现有对手方
 * @param {string} type
 */
const onAddToCounterparty = (type: string) => {
  if (
    ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
    !currentEntityPermission.value?.counterparty?.create
  ) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  addToCounterpartyType.value = type
  selectCounterpartyDialogRef.value?.onCheckSelectCounterpartyDialog()
}

const onResetList = () => {
  multipleSelection.value = []
  tableListRef.value?.clearSelection()
  emit('onResetList')
}

/**
 * @description: 行样式
 * @param {*} data
 * @return {string}
 */
const rowClassName = (data: any) => {
  let name = 'elv-balance-verify-task-transfer-table-row'
  if (data?.row?.transferType === 'OUTSIDE') {
    name += ' is-outside'
  }
  return name
}

// eslint-disable-next-line no-unused-vars
const onCellClicked = async (row: any, column: any, e: any) => {
  try {
    overlayDrawerData.currentData = {}
    currentData.value = {}
    if (column?.no === 6) {
      const direction = e.target?.attributes['data-type']?.value
      switch (direction) {
        case 'from':
          if (row?.fromEntityAccount?.address) {
            overlayDrawerData.drawerTitle = 'Account'
            overlayDrawerData.currentData = {
              value: row?.fromAddress,
              source: { ...row?.source },
              platform: { ...row?.fromEntityAccount?.platform },
              ...row?.fromEntityAccount
            }
          } else if (row?.fromAddressContact?.contactId) {
            overlayDrawerData.drawerTitle = 'Contact'
            overlayDrawerData.currentData = {
              value: row?.fromAddress,
              source: { ...row?.source },
              counterparty: row?.counterparty ?? {},
              ...row?.fromAddressContact
            }
          } else {
            switch (row?.direction) {
              case 'OUT':
                overlayDrawerData.drawerTitle = 'Account'
                overlayDrawerData.currentData = {
                  value: row?.fromAddress,
                  source: { ...row?.source }
                }
                break
              default:
                overlayDrawerData.drawerTitle = 'Contact'
                overlayDrawerData.currentData = {
                  value: row?.fromAddress,
                  counterparty: row?.counterparty ?? {},
                  source: { ...row?.source }
                }
                break
            }
          }
          break
        default:
          if (row?.toEntityAccount?.address) {
            overlayDrawerData.drawerTitle = 'Account'
            overlayDrawerData.currentData = {
              value: row?.toAddress,
              source: { ...row?.source },
              platform: { ...row?.toEntityAccount?.platform },
              ...row?.toEntityAccount
            }
          } else if (row?.toAddressContact?.role) {
            overlayDrawerData.drawerTitle = 'Contact'
            overlayDrawerData.currentData = {
              value: row?.toAddress,
              source: { ...row?.source },
              counterparty: row?.counterparty ?? {},
              ...row?.toAddressContact
            }
          } else {
            switch (row?.direction) {
              case 'IN':
                overlayDrawerData.drawerTitle = 'Account'
                overlayDrawerData.currentData = {
                  value: row?.toAddress,
                  source: { ...row?.source }
                }
                break
              default:
                overlayDrawerData.drawerTitle = 'Contact'
                overlayDrawerData.currentData = {
                  value: row?.toAddress,
                  counterparty: row?.counterparty ?? {},
                  source: { ...row?.source }
                }
                break
            }
          }
          break
      }
      overlayDrawerData.isBatch = false
      overlayDrawerData.type = 'transaction'
      currentData.value = row
    } else if (
      column?.no !== 9 &&
      column?.no !== 10 &&
      e.target?.className !== 'elv-financials-cell-date-time-main-bottom__copy' &&
      e.target?.className !== 'elv-financials-cell-date-time-main-bottom__txHash'
    ) {
      if (
        ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
        !currentEntityPermission.value?.transaction?.viewDetail
      ) {
        ElMessage.warning(t('message.noPermission'))
        return
      }
      overlayDrawerData.currentData = row
      transactionEditOverlayRef.value?.onCheckDrawerStatus()
    }
  } catch (error: any) {
    console.log(error)
    ElMessage.error(error.message)
  }
}

defineExpose({
  ref: tableListRef
})

onBeforeUnmount(() => {})
</script>

<style lang="scss">
.elv-balance-verify-task-transfer-table {
  --el-table-border-color: #d4dce5;
  --el-table-border: 1px solid #edf0f3;
  --el-fill-color-lighter: #f9fafb;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #d4dce6;
  border-right: 0px;
  border-left: 0px;
  border-bottom-width: 1px;

  .el-table__empty-block {
    order: 2;
  }

  .el-table__inner-wrapper {
    width: 100%;
    height: 100%;

    &::after,
    &::before {
      width: 0;
      height: 0;
    }
  }

  &.el-table--border {
    border-bottom-color: #edf0f3;

    &::after,
    &::before {
      width: 0;
      height: 0;
    }
  }

  .el-table__body {
    order: 1;
    border-top: v-bind('tableContentTopBorder');
  }

  .el-table__body-wrapper {
    min-height: 100px;
    // .el-scrollbar__wrap {
    //   max-height: 100px;
    // }

    .el-scrollbar__view {
      width: 100%;
      margin-bottom: 0px !important;
      display: flex !important;
      flex-direction: column;
    }
  }

  .el-table__border-left-patch {
    width: 0;
  }

  .elv-balance-verify-task-transfer-table-header {
    background: #eef4fb;

    .elv-balance-verify-task-transfer-table-header__cell {
      background: #eef4fb !important;
      height: 36px;
      box-sizing: border-box;
      border-right-color: #e4e7eb;
      border-bottom-color: #ced7e0;
      padding: 0px;

      &:last-child {
        border-right: 0px;

        &.el-table-fixed-column--right {
          right: 0;
        }
      }

      &:nth-last-child(2) {
        &.el-table-fixed-column--right {
          right: 44px;
        }
      }

      &.is-right .cell {
        justify-content: flex-end;
      }

      .cell {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: #666d74;
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-items: center;
      }
    }
  }

  .elv-balance-verify-task-transfer-table-row.is-outside {
    background-color: #f9fafb;
  }

  .el-table__append-wrapper {
    order: 0;
    height: 42px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    background: #fff;
    position: sticky;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 99;

    .elv-balance-verify-task-transfer-table-row__cell-append {
      display: flex;
      align-items: center;
      cursor: pointer;

      span {
        color: #838d95;
        font-family: 'Plus Jakarta Sans';
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-decoration-line: underline;
        text-decoration-style: solid;
        text-decoration-skip-ink: none;
        text-decoration-thickness: auto;
        text-underline-offset: auto;
        text-underline-position: from-font;
        margin-right: 2px;
      }

      svg {
        transform: rotate(180deg) translateY(-20%);
      }
    }
  }

  .elv-balance-verify-task-transfer-table-row__cell {
    padding: 0;
    height: 42px;

    &:last-child {
      border-right: 0px;
    }

    .cell {
      padding: 0 10px;
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      color: #0e0f11;
      line-height: 16px;
    }

    &.is-right .cell {
      text-align: right;
    }

    &.is-center .cell {
      text-align: center;
    }

    .elv-financials-cell-topBottom-conent {
      margin-top: 0;
      margin-bottom: 0;
    }

    .elv-balance-verify-task-transfer-table-row__cell-type {
      display: flex;
      align-items: center;
      white-space: nowrap;
      color: #0e0f11;
      font-family: 'Plus Jakarta Sans';
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      svg {
        overflow: visible;
        margin-right: 4px;
      }
    }

    &.elv-balance-verify-task-transfer-table-row__index .cell {
      color: #aaafb6;
      font-family: 'Barlow';
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      white-space: nowrap;
    }

    .elv-balance-verify-task-transfer-table-row__balance-verify {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 16px;
        height: 16px;
      }

      img {
        width: 16px;
        height: 16px;
        display: block;
      }

      .waiting {
        fill: #feb69c;
      }
    }

    &.elv-balance-verify-task-transfer-table-row__auxiliary .cell {
      display: flex;
      align-items: center;
      gap: 4px;

      .elv-balance-verify-task-transfer-table-row__auxiliary-list,
      .elv-balance-verify-task-transfer-table-row__auxiliary-item {
        display: flex;
        align-items: center;
      }

      .elv-balance-verify-task-transfer-table-row__auxiliary-list {
        gap: 4px;
      }

      .elv-balance-verify-task-transfer-table-row__auxiliary-item {
        box-sizing: border-box;
        padding: 4px 12px;
        justify-content: center;
        white-space: nowrap;
        color: #0e0f11;
        font-family: 'Plus Jakarta Sans';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        border-radius: 12px;
        border: 1px solid #edf0f3;
        background: #f9fafb;
      }
    }

    &.el-table-column--selection {
      .cell {
        width: 100%;
        padding: 0px;
        align-items: center;
        justify-content: center;
      }
    }

    .elv-balance-verify-task-transfer-table-row__operating {
      width: 100%;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;

      .elv-balance-verify-task-transfer-table-row__operating-icon {
        fill: #838d95;
        cursor: pointer;
      }

      .el-tooltip__trigger {
        color: #2f63eb;
        font-family: 'Plus Jakarta Sans';
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        display: flex;
        cursor: pointer;
        align-items: center;
      }
    }

    .elv-balance-verify-task-transfer-table-row__cell-protocol {
      display: flex;
      align-items: center;

      img {
        width: 18px;
        height: 18px;
        margin-right: 6px;
        border-radius: 50%;
      }
    }
  }
}

.elv-transactions-pagination__sizes-popper.el-popper {
  transform: translateY(10.5px);
}

.elv-journal-delete-message-box.el-dialog {
  .el-dialog__body {
    padding: 23px 24px 26px;

    span {
      color: #636b75;
      font-family: 'Plus Jakarta Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}

.ant-popover.elv-balance-verify-task-transfer-table-row__operating-popover {
  padding: 0px;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 6px;
  background: #fff;
  box-shadow:
    0px 0px 1px 0px rgba(0, 0, 0, 0.3),
    0px 2px 6px 0px rgba(0, 0, 0, 0.05);

  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-inner {
    padding: 0px;
    box-shadow: none;
    width: 161px;
    border-radius: 6px;
  }

  .elv-balance-verify-task-transfer-table-row__operating-item {
    height: 32px;
    width: 100%;
    padding: 0px 8px;
    box-sizing: border-box;
    align-items: center;
    align-self: stretch;
    color: #1e2024;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    transition: background 0.1s;
    display: flex;
    flex-direction: column;
    position: relative;

    svg {
      margin-right: 8px;
    }

    &.is-disabled {
      color: #aaafb6;
      cursor: not-allowed;
    }

    &:hover {
      background: #f9fafb;
    }
  }
}

.elv-pagination.elv-balance-verify-task-transfer-list-pagination {
  height: 58px;
  margin-top: 0;
}
</style>
<style lang="scss" scoped>
.elv-balance-verify-task-transfer-table-container {
  width: 100%;
  height: v-bind('containerHeight');
  position: relative;
  display: flex;
  flex-direction: column;
}

.elv-balance-verify-task-transfer-table-content {
  display: flex;
  flex: 1;
  min-height: 0px;
  position: relative;
}

.elv-balance-verify-task-transfer-table-bottom-append {
  height: 42px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #edf0f3;
  box-sizing: border-box;
  background: #fff;

  .elv-balance-verify-task-transfer-table-bottom-append__cell {
    display: flex;
    align-items: center;
    cursor: pointer;

    span {
      color: #838d95;
      font-family: 'Plus Jakarta Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-decoration-line: underline;
      text-decoration-style: solid;
      text-decoration-skip-ink: none;
      text-decoration-thickness: auto;
      text-underline-offset: auto;
      text-underline-position: from-font;
      margin-right: 2px;
    }
  }
}
</style>
