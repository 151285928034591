<template>
  <div class="elv-balance-verification-task-detail-container">
    <DetailHeader
      :entityTimezone="entityTimezone"
      :exportParams="exportParams"
      :detailLoading="detailLoading"
      :taskDetail="treasuryBalanceVerifyTaskDetail"
      :currentEntityTimezone="currentEntityTimezone"
    />

    <VerificationTips
      :entityTimezone="entityTimezone"
      :entityDetail="entityStore.entityDetail"
      :taskDetail="treasuryBalanceVerifyTaskDetail"
      :currentEntityTimezone="currentEntityTimezone"
      :loading="refreshVerificationTaskLoading"
      @onRefreshVerificationTask="onRefreshVerificationTask"
    />

    <ExportDataTaskHistoryInfo :exportType="exportProgressStatusType.balanceVerifyTaskDetail" />

    <IntervalBalance
      interval="STARTING"
      :entityTimezone="entityTimezone"
      :detailLoading="detailLoading"
      :datetime="treasuryBalanceVerifyTaskDetail?.beginDatetime ?? ''"
      :source="treasuryBalanceVerifyTaskDetail?.beginSource ?? null"
      :currentEntityTimezone="currentEntityTimezone"
      :currency="treasuryBalanceVerifyTaskDetail?.underlyingCurrency?.showSymbol ?? ''"
      :balance="treasuryBalanceVerifyTaskDetail?.beginBalance ?? '0'"
    />

    <div class="elv-balance-verification-task-detail-table-List">
      <ListTable
        ref="listTableRef"
        v-model:previousTransfers="previousTransfers"
        v-model:subsequentTransfers="subsequentTransfers"
        :transactionsParams="transactionsParams"
        :tableData="transactionsData"
        :tableLoading="tableLoading"
        :isLastPage="isLastPage"
        @onChangePage="onChangePage"
        @onChangePageSize="onChangePageSize"
        @onResetList="getTreasuryBalanceVerifyTaskTransactionList"
        @onShowOutsideTransfers="getBalanceVerifyTaskTransactionOutsideList"
      />
    </div>

    <EndingBalanceFormula
      :detailLoading="detailLoading"
      :balance="treasuryBalanceVerifyTaskDetail?.calculateEndBalance ?? '0'"
      :currency="treasuryBalanceVerifyTaskDetail?.underlyingCurrency?.showSymbol ?? ''"
    />

    <IntervalBalance
      interval="ENDING"
      :entityTimezone="entityTimezone"
      :detailLoading="detailLoading"
      style="border-top: 0; border-bottom: 1px solid #ced7e0"
      :datetime="treasuryBalanceVerifyTaskDetail?.endDatetime ?? ''"
      :source="treasuryBalanceVerifyTaskDetail?.endSource ?? null"
      :currentEntityTimezone="currentEntityTimezone"
      :currency="treasuryBalanceVerifyTaskDetail?.underlyingCurrency?.showSymbol ?? ''"
      :balance="treasuryBalanceVerifyTaskDetail?.endBalance ?? '0'"
    />

    <BalanceDifference
      :detailLoading="detailLoading"
      :entityTimezone="entityTimezone"
      :taskDetail="treasuryBalanceVerifyTaskDetail"
      :entityDetail="entityStore.entityDetail"
      @onOpenExplanationDialog="explanationDialogRef?.onCheckExplainDialog()"
      @onResetDetail="onResetDetail"
    />

    <ExplanationDialog
      ref="explanationDialogRef"
      :currentData="treasuryBalanceVerifyTaskDetail ?? ({} as TreasuryBalanceVerificationDetailType)"
      @onResetList="onResetDetail"
    />
  </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { ElMessage } from 'element-plus'
import { concat, uniqBy } from 'lodash-es'
import timezoneList from '@/config/timezone'
import AccountsApi from '@/api/AccountsApi'
import timezone from 'dayjs/plugin/timezone'
import ListTable from './components/ListTable.vue'
import DetailHeader from './components/Header.vue'
import {
  TreasuryBalanceVerificationDetailType,
  TreasuryBalanceVerificationTaskOutsideDirectionType
} from '#/AccountsTypes'
import { useEntityStore } from '@/stores/modules/entity'
import { TransactionItemType } from '#/TransactionsTypes'
import { exportProgressStatusType } from '@/config/global'
import { useAccountStore } from '@/stores/modules/accounts'
import IntervalBalance from './components/IntervalBalance.vue'
import VerificationTips from './components/VerificationTips.vue'
import BalanceDifference from './components/BalanceDifference.vue'
import ExplanationDialog from '../components/ExplanationDialog.vue'
import EndingBalanceFormula from './components/EndingBalanceFormula.vue'
import ExportDataTaskHistoryInfo from '../../../../components/ExportDataTaskHistoryInfo.vue'

dayjs.extend(utc)
dayjs.extend(timezone)

const { t } = useI18n()
const route = useRoute()
const listTableRef = useTemplateRef('listTableRef')
const explanationDialogRef = useTemplateRef('explanationDialogRef')

const entityStore = useEntityStore()
const accountStore = useAccountStore()
const { treasuryBalanceVerifyTaskDetail } = storeToRefs(accountStore)

const entityTimezone = unref(entityStore.entityDetail?.timezone ?? 'UTC')

const transactionsParams = ref({
  page: 1,
  limit: 100
})

const transactionsData: any = ref({
  totalCount: 0,
  list: []
})
const loading = ref(false)
const tableLoading = ref(true)
const detailLoading = ref(false)
const previousTransfers = ref(false)
const subsequentTransfers = ref(false)
const refreshVerificationTaskLoading = ref(false)
const previousTransfersList = ref<TransactionItemType[]>([])
const subsequentTransfersList = ref<TransactionItemType[]>([])

const entityId = computed(() => {
  return String(route.params?.entityId as string)
})

const currentEntityPermission = computed(() => {
  return entityStore.entityPermission()
})

const currentEntityTimezone = computed(() => {
  return timezoneList.find((item) => item.area === entityTimezone)?.timezone ?? 'UTC+00:00'
})

const treasuryBalancePeriodId = computed(() => {
  return String(route.params?.treasuryBalancePeriodId as string)
})

const exportParams = computed(() => {
  return {
    type: 'TREASURY_BALANCE_VERIFY_TASK_PERIOD',
    extra: {
      entityId: entityId.value,
      treasuryBalancePeriodId: treasuryBalancePeriodId.value,
      previousTransfers: previousTransfers.value,
      subsequentTransfers: subsequentTransfers.value
    }
  }
})

const isLastPage = computed(() => {
  return transactionsParams.value.page === Math.ceil(transactionsData.value.totalCount / transactionsParams.value.limit)
})

/**
 * @description: 余额校验
 */
const onRefreshVerificationTask = async () => {
  if (entityStore.entityDetail?.treasuryVerifyStatus === 'DOING') {
    ElMessage.warning(t('message.verificationTaskInProgressTipMessage'))
    return
  }
  if (entityStore.entityDetail?.progress.transactionStatus === 'DOING') {
    ElMessage.warning(t('message.TransactionInProgress'))
    return
  }
  if (
    ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
    !currentEntityPermission.value?.entityAccount?.balanceVerify
  ) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  try {
    refreshVerificationTaskLoading.value = true
    await AccountsApi.executeTreasuryBalanceVerify(entityId.value)
    await entityStore.fetchEntityDetail(entityId.value)
    ElMessage.success(t('message.BalanceVerificationStarted'))
  } catch (error: any) {
    ElMessage.error(error.message)
  } finally {
    refreshVerificationTaskLoading.value = false
  }
}

/**
 * 合并交易列表
 */
const mergeTransactionLists = () => {
  let mergedList = transactionsData.value.list || []
  if (previousTransfers.value && transactionsParams.value.page === 1) {
    mergedList = concat(previousTransfersList.value, mergedList)
  }
  if (subsequentTransfers.value && isLastPage.value) {
    mergedList = concat(mergedList, subsequentTransfersList.value)
  }
  transactionsData.value.list = uniqBy(mergedList, (item: any) => item.transactionId)
  transactionsData.value.list.forEach((item: any, index: number) => {
    const previousTransfersListLength = transactionsParams.value.page === 1 ? 0 : previousTransfersList.value.length
    item.rowIndex =
      (transactionsParams.value.page - 1) * transactionsParams.value.limit + index + 1 + previousTransfersListLength
  })
}

/**
 * @description: 获取余额校验任务区间内的交易列表
 */
const getTreasuryBalanceVerifyTaskTransactionList = async () => {
  try {
    tableLoading.value = true
    const response = await accountStore.fetchTreasuryBalanceVerifyTaskTransactionList(
      entityId.value,
      treasuryBalancePeriodId.value,
      transactionsParams.value
    )
    listTableRef.value?.ref?.setScrollTop(0)
    transactionsData.value = response
    mergeTransactionLists()
  } catch (error) {
    console.log(error)
  } finally {
    tableLoading.value = false
  }
}

/**
 * 余额校验任务区间外的交易列表
 * @param {string} entityId 主体id
 * @property {string} direction PREVIOUS | SUBSEQUENT
 */
const getBalanceVerifyTaskTransactionOutsideList = async (
  direction: TreasuryBalanceVerificationTaskOutsideDirectionType
) => {
  try {
    tableLoading.value = true
    const { data } = await AccountsApi.getTreasuryBalanceVerifyTaskTransactionOutsideList(
      entityId.value,
      treasuryBalancePeriodId.value,
      {
        direction
      }
    )
    if (direction === TreasuryBalanceVerificationTaskOutsideDirectionType.PREVIOUS) {
      previousTransfersList.value = data
    } else {
      subsequentTransfersList.value = data
    }
    mergeTransactionLists()
  } catch (error) {
    console.log(error)
  } finally {
    tableLoading.value = false
  }
}

/**
 * @description: 更改每页显示数量
 * @param {number} limit 每页显示数量
 */
const onChangePageSize = (limit: number) => {
  transactionsParams.value.limit = limit
  transactionsParams.value.page = 1
  getTreasuryBalanceVerifyTaskTransactionList()
}

/**
 * @description: 更改当前页数
 * @param {number} page 当前页数
 */
const onChangePage = (page: number) => {
  transactionsParams.value.page = page
  getTreasuryBalanceVerifyTaskTransactionList()
}

/**
 * @description: 获取余额校验任务详情
 */
const getTreasuryBalanceVerifyTaskDetail = async () => {
  try {
    detailLoading.value = true
    await accountStore.fetchTreasuryBalanceVerifyTaskDetail(entityId.value, treasuryBalancePeriodId.value)
  } catch (error: any) {
    console.log(error)
    ElMessage.error(error.message)
  } finally {
    detailLoading.value = false
  }
}

const onResetDetail = () => {
  accountStore.treasuryBalanceVerifyTaskDetail = null
  getTreasuryBalanceVerifyTaskDetail()
}

watch(
  () => route,
  async (newValue) => {
    try {
      if (newValue.name === 'entity-accounts-balance-verify-task-detail' && treasuryBalancePeriodId.value) {
        loading.value = true
        getTreasuryBalanceVerifyTaskDetail()
        await getTreasuryBalanceVerifyTaskTransactionList()
      }
    } catch (error) {
      console.log(error)
    } finally {
      loading.value = false
    }
  },
  { immediate: true }
)

onBeforeUnmount(() => {
  accountStore.treasuryBalanceVerifyTaskDetail = null
  accountStore.balanceVerifyTransferList = {
    list: [],
    totalCount: 0
  }
  previousTransfers.value = false
  subsequentTransfers.value = false
  previousTransfersList.value = []
  subsequentTransfersList.value = []
})
</script>

<style lang="scss" scoped>
.elv-balance-verification-task-detail-container {
  width: 100%;
  height: calc(100vh - 98px);
  position: relative;
  display: flex;
  flex-direction: column;

  .elv-balance-verification-task-detail-table-List {
    flex: 1;
    min-height: 0px;
    position: relative;
  }
}
</style>
